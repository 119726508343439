<template>
  <div>
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />
    <div
      v-show="!isLoading"
      id="ellipsis"
    >
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col align-items-center">
          <h5>
            <template v-if="!task_uid || !task">Task detail</template>
            <span v-else-if="task?.title != 'null' && task?.title != ''">{{ task?.task_type_name }} - {{ task?.title }}</span>
            <span v-else>{{ task?.task_type_name }}</span>

            <span
              @click="copyToClipboard()"
              class="text-12 pl-2 cursor-pointer"
              v-if="task"
            >
              <span
                class="p-1"
                style="
                  background: rgb(232, 236, 239) !important;
                  border-radius: 6px;
                "
              ><i class="las la-link text-primary"></i></span></span>

          </h5>

          <div
            v-if="job"
            class="font-light"
          >
            <Button
              @click="routeJob($event)"
              class="p-button-link font-light pl-0 text-link-hover"
            >
              {{ job.job_number }} - {{ job.title }}
            </Button>
          </div>
        </div>
        <!-- v-if="!taskFormMode && task.is_complete !== '0'" -->

        <div
          class="field col flex-grow-0"
          style="align-self: self-start"
        >

          <div class="p-inputgroup flex justify-content-end align-items-center">
            <div><span
                class="text-12"
                v-if="task"
              ><span
                  :style="getTagStyle() + 'borderRadius: 6px;text-wrap-mode: nowrap;'"
                  class="p-2"
                >{{
                task.is_approved == 0
                  ? "Draft"
                  : task.in_review == 1
                  ? "In Review"
                  : task.is_complete == 1
                  ? "Complete"
                  : task.date_due?.time_until == "Overdue"
                  ? "Overdue"
                  : "Active"
              }}</span></span>
              <span
                class="text-12"
                v-else
              ><span
                  :style="getTagStyle() + 'borderRadius: 6px'"
                  class="p-2"
                >Draft</span></span>
            </div>
            <template v-if="!taskFormMode">
              <div
                v-if="task?.reviewed_by == null || task?.is_complete == 1"
                class="relative"
              >
                <Button
                  @click="markComplete()"
                  :disabled="task?.is_complete == 1"
                  style="height: 30px; width: 30px"
                  icon="las la-check-circle i-20"
                  :class="
                  'p-button-sm p-button-rounded p-12 mr-3 ml-3 ' +
                  getTaskCompleteClass(task?.is_complete)
                "
                  v-if="!isSwitching"
                />
                <ProgressSpinner
                  class="p-12 h-2rem w-2rem p-0 mr-3"
                  style="width: 18px; height: 18px"
                  v-else
                />
              </div>
              <div v-else>
                <Button
                  @click="sendForReview()"
                  icon="las la-user-clock i-20"
                  :class="
                  'p-button-sm p-button-rounded h-2rem w-1rem p-12 mr-3 cursor-pointer ' +
                  getTaskInReviewClass(task.in_review)
                "
                  v-if="!isSwitching"
                />
                <ProgressSpinner
                  class="p-12 h-2rem w-2rem p-0 mr-3"
                  style="width: 18px; height: 18px"
                  v-else
                />
              </div>
              <!-- <i
              v-if="!taskFormMode && tab == 'Task' && task.is_complete != 1"
              :class="getTaskEditModeClass(task)"
              @click="taskFormMode = true"
            /> -->
              <i
                class="las la-ellipsis-h text-20 cursor-pointer"
                @click="toggle"
              />

              <Menu
                :model="items"
                ref="subMenu"
                :popup="true"
                appendTo="body"
              />
            </template>
          </div>
        </div>
        <div class="col-12">
          <div class="flex flex-wrap">
            <template v-if="task && task.tags">
              <div
                v-for="t in task.tags.filter((a) => a.name)"
                :key="t.uid"
              >
                <TaskTag
                  :task="task"
                  :tag="t"
                ></TaskTag>
              </div>
            </template>
            <span
              @click="toggleTags"
              v-if="task_uid && task"
            >
              <Chip
                class="cursor-pointer mb-3"
                style="background-color: #f8f9fa"
              >
                <div class="flex p-1">
                  <i
                    class="las la-plus"
                    style="padding-bottom: 1px; margin-top: 1px"
                  ></i>
                </div>
              </Chip>
            </span>

            <OverlayPanel
              ref="tagModal"
              appendTo="body"
            >
              <TaskTags
                @addTag="addTag"
                :task="task"
              ></TaskTags>
            </OverlayPanel>
          </div>
        </div>
        <!-- <div class="flex text-center" v-if="task_uid">
          <div
            v-for="r in tabs"
            :key="r.header"
            :style="getHeaderBorderStyle(r.header)"
          >
            <Button
              :label="r.header"
              @click="tab = r.header"
              class="p-button-text p-button-secondary"
              :style="getHeaderStyle(r.header)"
            />
          </div>
        </div> -->
        <div
          class="flex col-12 text-center p-tab-show-panel mb-3"
          v-if="task_uid"
        >
          <TabView
            @tab-click="nav"
            :activeIndex="index"
            :scrollable="false"
          >
            <TabPanel
              v-for="(r, i) in tabs"
              :key="r.header"
            ><template #header>
                <i
                  style="margin-right: 4px"
                  :class="r.icon"
                ></i>
                <span :style="getHeaderStyle(i)">{{ r.header }}</span>
              </template>
            </TabPanel>
          </TabView>
        </div>
        <div class="col-12">
          <transition
            name="slide-fade"
            style="overflow-x: hidden"
          >
            <template v-if="tab == 'Task'">

              <TaskOverview
                v-if="!taskFormMode"
                :task="task"
              > </TaskOverview>
              <TaskForm
                @cancel="taskFormMode = !taskFormMode"
                :taskProp="task"
                @setTaskFormMode="taskFormMode = $event"
                v-else
              ></TaskForm>
            </template>
            <TaskBriefAmendments
              :task="task"
              v-if="tab == 'Brief'"
            ></TaskBriefAmendments>
            <TaskNotes
              :taskProp="task"
              v-if="tab == 'Notes'"
            ></TaskNotes>

            <TaskTime
              :taskProp="task"
              v-if="tab == 'Time'"
            ></TaskTime>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { state, fetchTask } from "../../../services/data_service.js";
import TaskNotes from "@/application/Tasks/components/TaskNotes";
import TaskForm from "@/application/Tasks/components/TaskForm";
import TaskOverview from "@/application/Tasks/components/TaskOverview";
import TaskTime from "@/application/Tasks/components/TaskTime";
import TaskBriefAmendments from "@/application/Tasks/components/TaskBriefAmendments";
import TaskTags from "@/application/Tasks/components/TaskTags";
export default {
  name: "TaskSlider",
  components: {
    TaskForm,
    TaskNotes,
    TaskBriefAmendments,
    TaskTime,
    TaskOverview,
    TaskTags,
  },
  data() {
    return {
      isSwitching: false,
      index: 0,
      taskFormMode: false,
      tab: "Task",
      contextMenuItems: [
        {
          label: "Mark as Completed",
          command: () => {
            this.markComplete();
            if (this.task.in_review == 1) {
              this.sendForReview();
            }
          },
        },
        {
          label: "Mark as in Progress",
          command: () => {
            this.markComplete();
            if (this.task.in_review == 1) {
              this.sendForReview();
            }
          },
        },
        {
          label: "Send for Review",
          command: () => {
            this.sendForReview();
            if (this.task.is_complete == 1) {
              this.markComplete();
            }
          },
        },
        {
          label: "Edit",
          command: () => {
            this.taskFormMode = true;
          },
        },
        {
          label: "Delete",
          command: () => {
            this.deletePrompt(this.task.title, "/v1/tasks/" + this.task.uid);
            this.clearSliderContent();
          },
        },
      ],
      tabTemplates: [
        {
          header: "Task",
          icon: "las la-check",
          permission: null,
        },

        {
          header: "Brief",
          icon: "las la-folder-open",
          permission: null,
        },
        {
          header: "Notes",
          icon: "las la-comments",
          permission: null,
        },
        {
          header: "Time",
          icon: "las la-stopwatch",
          permission: "admin",
        },
      ],
      tabs: [],
    };
  },
  created() {
    if (this.selectedObject && this.selectedObject.mode === "brief") {
      this.index = 1;
      this.tab = "Brief";
    }
    if (this.selectedObject && this.selectedObject.mode === "notes") {
      this.index = 2;
      this.tab = "Notes";
    }
    this.$bus.on("refreshData", this.loadData);

    this.tabTemplates.forEach((t) => {
      if (this.canView(t.permission) || t.permission == null) {
        this.tabs.push(t);
      }
    });
  },

  async mounted() {
    this.$store.dispatch("setSliderLoader", true);
    if (!this.task_uid) {
      this.taskFormMode = true;
    } else {
      await this.loadData();
      this.taskFormMode = state.editTaskMode;
    }

    if (!this.taskFormMode) {
      this.$store.dispatch("setSliderLoader", false);
    }
  },
  beforeDestroy() {
    state.editTaskMode = false;
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    getTaskEditModeClass(t) {
      return t.is_complete == 1
        ? "las la-pen text-16 mr-3 disabled-edit-mode pointer-events-none"
        : "las la-pen text-16 mr-3 cursor-pointer";
    },
    getTaskCompleteClass(t) {
      return t == "1"
        ? "task-icon-complete grey-button"
        : "grey-button cursor-pointer";
    },
    getTaskInReviewClass(t) {
      return t == 1 ? "task-icon-review" : "grey-button";
    },
    routeJob(event) {
      const jobUrl = "/job/" + this.task.job.uid;

      // If Ctrl key is pressed, open in new tab regardless of current route
      if (event && (event.ctrlKey || event.metaKey)) {
        window.open(window.location.origin + jobUrl, "_blank");
        return;
      }

      // Otherwise, only navigate if it's not the current job
      if (this.task.job.uid === this.$route.params.uid) {
        return;
      }

      this.$router.push(jobUrl);
      this.clearSliderContent();
    },
    toggle(event) {
      this.$refs.subMenu.toggle(event);
    },
    markComplete() {
      this.isSwitching = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            `/v1/tasks/${this.task_uid}/complete/all`,
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.task.is_complete = response.data.data.toString();
          this.$toast.add({
            severity: "success",
            summary:
              "Task marked as " +
              (response.data.data == 1 ? "completed" : "incomplete"),

            life: 2000,
          });
        });
    },
    sendForReview() {
      this.isSwitching = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            `/v1/tasks/${this.task_uid}/review/all`,
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.task.in_review = response.data.data.toString();
          if (response.data.data == 1) {
            this.$toast.add({
              severity: "success",
              summary: "Task sent for review",
              life: 2000,
            });
          }
        });
    },
    nav(ev) {
      this.tab = this.tabs[ev.index].header;
      this.index = ev.index;
    },
    toggleTags(event) {
      this.$refs.tagModal.toggle(event);
    },
    addTag(tag) {
      state.task.tags.push(tag);
    },

    getTagStyle() {
      console.log(this.task);
      if (!this.task) {
        return "background:  rgb(232, 236, 239) !important;";
      }

      if (this.task?.is_approved == 0) {
        return "background:  rgb(232, 236, 239) !important;";
      }
      if (
        this.task?.date_due?.time_until === "Overdue" &&
        this.task?.is_complete == 0 &&
        this.task?.in_review == 0
      ) {
        return "background: #FFF1F7 !important;color: #FE4D97 !important;";
      } else if (this.task?.in_review == 1) {
        return "background: #FFF2DE !important;color: #FFAB2B !important;";
      }
      return "background: #f4fbef !important;color: #6dd230 !important;";
    },

    getHeaderStyle(index) {
      if (this.index !== index) {
        return "font-weight: lighter !important;";
      }
    },

    getHeaderBorderStyle(route) {
      if (this.tab == route) {
        return "border-bottom: 2px solid #4D7CFE;";
      }
    },
    async loadData() {
      await fetchTask(this.task_uid);
    },
    copyToClipboard() {
      // Extract meta tag information
      const title = this.task.title;

      // get full route url
      let url = window.location.href;

      // if the does not contain /job/ then copy origin/job/job_uid
      if (!url.includes("/job/")) {
        const jobUrl = `/job/${this.task.job.uid}`;
        url = window.location.origin + jobUrl;
      }

      // Construct the rich link text
      const richLinkText = `${title}\n${url}`;

      // Create a temporary textarea element to hold the link text
      const el = document.createElement("textarea");
      el.value = richLinkText;
      document.body.appendChild(el);

      // Select the content and copy it to the clipboard
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      // Show a success toast notification
      this.$toast.add({
        severity: "success",
        summary: "Link copied to clipboard",
        life: 2000,
      });
    },
    getContextMenu(array) {
      var arr = [];
      array.forEach((element) => {
        arr.push(this.contextMenuItems[element]);
      });
      return arr;
    },
  },
  computed: {
    isJobCosting() {
      return this.$route.name === "job--costing";
    },
    items() {
      return this.getContextMenu([3, 4]);
      // if (this.task.is_complete != 1 && this.task.reviewed_by == null) {
      //   return this.getContextMenu([0, 3, 4]);
      // }
      // if (
      //   this.task.is_complete != 1 &&
      //   this.task.reviewed_by != null &&
      //   this.task.in_review != 1
      // ) {
      //   return this.getContextMenu([2, 0, 3]);
      // }
      // if (this.task.is_complete == 1) {
      //   return this.getContextMenu([1]);
      // }
      // if (this.task.in_review == 1 && this.task.reviewed_by != null) {
      //   return this.getContextMenu([0, 3]);
      // }
      // return [];
    },
    task_uid() {
      return this.selectedObject && !this.selectedObject.uid
        ? this.selectedObject
        : this.selectedObject && this.selectedObject.uid
        ? this.selectedObject.uid
        : null;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    job() {
      return this.task?.job ?? state.job;
    },
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
    task() {
      return state.task;
    },
  },
  watch: {
    "task.is_complete"() {
      this.isSwitching = false;
    },
    "task.in_review"() {
      this.isSwitching = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  margin: 1.3rem 0;
}
.text-link-hover:hover {
  color: #4d7cfe !important;
}
.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: none !important;
}
.p-tabview {
  width: 100%;
}
.task-icon-complete {
  background-color: #6dd230 !important;
  border-color: #6dd230 !important;
  color: white !important;
}

.task-icon-review {
  background-color: #ffab2b !important;
  border-color: #ffab2b !important;
  color: white !important;
}
</style>