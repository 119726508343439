var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[(!_vm.addNote)?_c('div',{staticClass:"formgrid grid flex align-items-center"},[_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":"Add a Note","icon":"las la-plus"},on:{"click":function($event){_vm.addNote = !_vm.addNote}}}),_c('Divider',{staticClass:"mb-3 ml-2 mr-2"})],1):_vm._e(),(_vm.addNote)?_c('form',{directives:[{name:"form-enter",rawName:"v-form-enter"}],staticClass:"formgrid grid flex align-items-center",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}}},[_c('div',{staticClass:"field col-12 mt-3"},[_c('Mentionable',{staticClass:"border-4",attrs:{"insert-space":"","offset":"6","keys":['@'],"items":_vm.users},on:{"apply":function($event){return _vm.handleApply(null)}},scopedSlots:_vm._u([{key:"no-result",fn:function(){return [_c('div',{staticClass:"flex align-items-center p-3 text-10"},[_vm._v("No Results")])]},proxy:true},{key:"item-@",fn:function({ item }){return [_c('div',{staticClass:"flex align-items-center p-3 cursor-pointer"},[_c('div',{staticClass:"flex-grow-0"},[_c('Avatar',{staticClass:"mr-3",style:({
                  'background-color': !item.profile_picture
                    ? _vm.getColorFromName(item)
                    : 'none',
                  color: '#ffffff',
                }),attrs:{"image":item.profile_picture,"label":!item.profile_picture ? _vm.getIntials(item) : null,"size":"large","shape":"circle"}})],1),_c('div',{staticClass:"flex flex-column"},[_c('div',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))]),_c('div',{staticClass:"text-10"},[_vm._v("@"+_vm._s(item.value))])])])]}}],null,false,3876940841)},[_c('Textarea',{ref:"comment",staticClass:"w-full",attrs:{"placeholder":"Note","autoResize":true,"rows":"5"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('div',{staticClass:"field col-12 cursor-pointer"},[_c('div',{staticClass:"flex justify-content-between pt-3 pb-3"},[_c('Button',{attrs:{"type":"submit"}},[_vm._v(" ADD NOTE ")]),_c('Button',{staticClass:"p-button-text p-button-secondary",on:{"click":function($event){_vm.addNote = false}}},[_vm._v(" CANCEL ")])],1)])]):_vm._e(),_vm._l((_vm.notes),function(n){return _c('div',{key:n.uid,staticClass:"p-fluid formgrid grid mt-3"},[_c('div',{staticClass:"field col-12"},[_c('div',{staticClass:"flex align-items-center parent-div"},[_c('div',{staticClass:"col flex-grow-0"},[_c('Avatar',{style:({
              'background-color': '#F2F4F6',
              color: '#B9C5D0',
            }),attrs:{"size":"large","shape":"circle","image":n.user.profile_picture}})],1),_c('div',{staticClass:"col flex flex-column"},[_c('div',[_vm._v(_vm._s(n.user.name))]),_c('div',{staticClass:"font-light text-sm"},[_vm._v(_vm._s(n.created_date.nice))])]),_c('i',{staticClass:"las la-trash i-20 grey hover-show cursor-pointer",on:{"click":function($event){return _vm.deletePrompt(
                n.note_text,
                '/v1/tasks/notes/' + n.uid
              )}}})])]),(n.title && n.title !== 'null')?_c('div',{staticClass:"field col-12",staticStyle:{"font-weight":"bold"}},[_c('div',{staticClass:"pl-3"},[_vm._v(_vm._s(n.title))])]):_vm._e(),_c('div',{staticClass:"field col-12"},[_c('div',{staticClass:"pl-3",domProps:{"innerHTML":_vm._s(_vm.handleNoteTags(n.note_text))}})]),_vm._l((n.comments),function(c){return _c('div',{key:c.uid,staticClass:"field col-12 ml-4 border-left parent-div"},[_c('div',{staticClass:"field col-12 mb-2"},[_c('div',{staticClass:"flex align-items-center pl-2"},[_c('div',{staticClass:"col flex flex-column"},[_c('div',[_vm._v(_vm._s(c.user.name))]),_c('div',{staticClass:"font-light text-sm"},[_vm._v(_vm._s(c.created_date.nice))])]),_c('i',{staticClass:"las la-trash i-20 grey hover-show cursor-pointer",on:{"click":function($event){return _vm.deletePrompt(
                c.note_text,
                '/v1/tasks/notes/' + c.uid
              )}}})])]),_c('div',{staticClass:"field col-12"},[_c('div',{staticClass:"pl-3",domProps:{"innerHTML":_vm._s(_vm.handleNoteTags(c.note_text))}})])])}),_c('div',{staticClass:"field col-12 pl-5"},[(_vm.addReply === n.uid)?_c('Mentionable',{attrs:{"insert-space":"","offset":"6","keys":['@'],"items":_vm.users},on:{"apply":function($event){return _vm.handleApply('reply' + n.uid)}},scopedSlots:_vm._u([{key:"item-@",fn:function({ item }){return [_c('div',{staticClass:"flex align-items-center p-3 cursor-pointer"},[_c('div',{staticClass:"flex-grow-0"},[_c('Avatar',{staticClass:"mr-3",style:({
                  'background-color': !item.profile_picture
                    ? _vm.getColorFromName(item)
                    : 'none',
                  color: '#ffffff',
                }),attrs:{"image":item.profile_picture,"label":!item.profile_picture ? _vm.getIntials(item.name) : null,"size":"large","shape":"circle"}})],1),_c('div',{staticClass:"flex flex-column"},[_c('div',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))]),_c('div',{staticClass:"text-10"},[_vm._v("@"+_vm._s(item.value))])])])]}}],null,true)},[_c('div',{staticClass:"p-inputgroup"},[_c('InputText',{ref:'reply' + n.uid,refInFor:true,staticClass:"w-full",attrs:{"placeholder":"Comment","autofocus":""},model:{value:(_vm.reply),callback:function ($$v) {_vm.reply=$$v},expression:"reply"}}),_c('Button',{staticClass:"p-button-primary",staticStyle:{"border-top-right-radius":"4px","border-bottom-right-radius":"4px"},attrs:{"icon":"las la-paper-plane"},on:{"click":function($event){_vm.saveReply(n.uid);
              _vm.reply = null;}}}),_c('Button',{staticClass:"p-button-rounded p-button-text p-button-secondary",attrs:{"icon":"la la-times"},on:{"click":function($event){_vm.addReply = null;
              _vm.comment = null;}}})],1)]):_c('div',{staticClass:"p-inputgroup"},[_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":"Add a Comment","icon":"las la-plus"},on:{"click":function($event){_vm.addReply = n.uid}}})],1)],1)],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }