<template>
  <div>
    <form
      class="formgrid grid flex align-items-center"
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
      autocomplete="off"
    >

      <template v-if="addBrief">
        <div class="col field flex align-items-flex-start font-light">
          Task Brief
        </div>
        <div class="field flex align-items-flex-end">
          <Button
            label="Add from Template"
            class="p-button-text p-button-md"
            icon="las la-plus"
          />
        </div>
        <div class="field col-12 pt-1">

          <Editor
            ref="editor"
            id="Description"
            :autoResize="true"
            v-model="newBrief"
            editorStyle="height:150px"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button><button class="ql-strike"></button>
                <button
                  class="ql-list"
                  value="ordered"
                ></button>
                <button
                  class="ql-list"
                  value="bullet"
                ></button>
                <button class="ql-align"></button>
                <button class="ql-link"></button>
              </span> </template>
          </Editor>
        </div>
        <div class="col field flex align-items-flex-start mt-3">Documents</div>
        <div class="field col-12 cursor-pointer">
          <file-pond
            name="profile"
            ref="pond"
            class="font-light"
            label-idle="Drop documents or select files"
            v-bind:allow-multiple="true"
            v-bind:files="myFiles"
            v-on:init="handleFilePondInit"
            v-on:processfile="handleProcessFile"
          />
        </div>
        <div class="flex justify-content-between pt-5 col-12">
          <Button type="submit"><template> ADD BRIEF </template></Button>
          <Button
            class="p-button-text p-button-secondary"
            @click="$emit('cancel')"
          >
            CANCEL
          </Button>
        </div>
      </template>

      <div
        class="field col-12"
        v-if="hasBrief"
      >
        <Panel
          :toggleable="true"
          :collapsed="isExpanded"
          class="no-icon-panel"
        >
          <template #header>
            <div
              class="flex w-full cursor-pointer p-3"
              @click="isExpanded = !isExpanded"
            >
              <div class="col font-normal">
                <i
                  class="pi pi-chevron-right mr-2"
                  :class="{ 'rotate-90': !isExpanded == true }"
                  style="vertical-align: middle"
                ></i>Task Brief
              </div>
              <div class="text-right font-normal pr-4">
                <span class="font-light">Estimated Time:</span>
                {{
                  task.original_hours
                    ? task.original_hours
                    : task.estimated_hours
                }}
              </div>
              <div class="text-right font-normal">
                <span class="font-light">Added:</span>
                <template v-if="task.created_date">
                  {{ task.created_date.nice }}</template>
              </div>
            </div>
          </template>
          <div v-if="!hasBrief">Nothing added yet...</div>
          <template v-else>
            <div
              @mouseenter="hover = true"
              @mouseleave="hover = false"
              class="relative"
            >
              <div
                v-if="editBrief === false"
                class="mr-4 htmlContainer"
                style="word-wrap: break-word"
                v-html="task.brief"
              ></div>
              <Editor
                ref="editor"
                v-else
                id="Description"
                :autoResize="true"
                v-model="task.brief"
                editorStyle="height:150px"
              >
                <template #toolbar>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button><button class="ql-strike"></button>
                    <button class="ql-strike"></button>
                    <button
                      class="ql-list"
                      value="ordered"
                    ></button>
                    <button
                      class="ql-list"
                      value="bullet"
                    ></button>
                    <button class="ql-align"></button>
                    <button class="ql-link"></button>
                  </span> </template>
              </Editor>
              <i
                v-if="hover && editBrief === false"
                @click="editBrief = true"
                class="las la-pen i-20 cursor-pointer grey absolute right-0 top-0"
              ></i>
            </div>
            <div
              class="field mt-3 font-light"
              v-if="task.files && task.files.length > 0"
            >
              Documents
            </div>
            <div
              v-for="(f, index) in task.files"
              :key="f.uid"
              :class="'field  ' + (index === 0 ? 'mt-3' : '')"
            >
              <Attachment
                @refresh="handleRefresh"
                :file="f"
              ></Attachment>
            </div>

            <Button
              v-if="!showDrop"
              icon="las la-plus"
              label="Add a Document"
              @click="showDrop = !showDrop"
              class="p-button-text p-button-secondary p-button-sm mt-3"
            />
            <div
              class="field col-12 cursor-pointer mt-3"
              v-if="showDrop"
            >
              <file-pond
                name="profile"
                ref="pond"
                class="font-light"
                label-idle="Drop documents or select files"
                v-bind:allow-multiple="true"
                v-bind:files="myFiles"
                v-on:init="handleFilePondInit"
                v-on:processfile="handleProcessFile"
              />
            </div>
            <div
              v-if="showDrop || editBrief"
              class="flex justify-content-between mt-3"
            >
              <Button
                type="submit"
                :loading="isLoading"
                label="SAVE"
              ></Button>
              <Button
                class="p-button-text p-button-secondary"
                @click="
                  showDrop = false;
                  editBrief = false;
                  $emit('cancel');
                "
              >
                CANCEL
              </Button>
            </div>
          </template>
        </Panel>
        <div
          class="font-light text-12 pt-2 text-end"
          v-if="task.brief_updated_date "
        >Edited: {{ formatDate(task.brief_updated_date) }} by {{task.brief_updated_by.name}} {{task.brief_updated_by.surname}}</div>
        <div
          class="font-light text-12 pt-2 text-end"
          v-else-if="task.brief_created_by && task.created_date"
        >Created: {{ task.created_date.nice }} by {{task.brief_created_by.name}} {{task.brief_created_by.surname}}</div>

      </div>
    </form>
  </div>
</template>

<script>
import { fetchTask, state } from "../../../services/data_service.js";
import vueFilePond, { setOptions } from "vue-filepond";
import Attachment from "@/application/Tasks/components/Attachment";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
var FilePond = vueFilePond(FilePondPluginFileValidateType);
export default {
  components: {
    Attachment,
    FilePond,
  },
  props: {
    taskProp: {
      type: Object,
    },
    addBrief: {
      type: Boolean,
    },
  },
  data() {
    return {
      editBrief: false,
      newBrief: null,
      hover: false,
      active: null,
      brief: null,
      showDrop: false,
      task: null,
      task_files: [],
      myFiles: [],
      hasBrief: false,
      isExpanded: false,
      isLoading: false,
    };
  },
  created() {
    if (this.taskProp) {
      this.task = this.taskProp;
    }
  },
  watch: {
    taskProp(n) {
      this.task = n;
    },
  },
  computed: {
    obj() {
      return this.$store.getters.selectedObject;
    },
    amendments() {
      return state.amendments;
    },
  },
  mounted() {
    this.hasBrief = this.task.brief ? true : false;
    if (this.obj) {
      this.active = this.amendments.length > 0 ? null : 0;
    } else {
      state.amendments = [];
    }
    setOptions({
      server: {
        url: process.env.VUE_APP_ROOT_API + "/",
        timeout: 7000,
        process: {
          url: "./v1/tasks/upload",
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.$store.getters.token,
          },
        },
      },
    });
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format("DD MMM YY");
    },
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async handleRefresh() {
      await fetchTask(this.task.uid);
    },
    async submitHandler() {
      try {
        if (this.task.uid) {
          this.$store.dispatch("setSliderLoader", true);
        }
        this.isLoading = true;
        this.loadingText = "Processing...";
        var form = new FormData();
        form.append("title", this.task.title);
        form.append("billable", this.task.billable);
        form.append("description", this.task.description);
        if (this.task?.date_start?.date) {
          form.append("date_start", this.task.date_start.date);
        }
        if (this.task?.date_due?.date) {
          form.append("date_due", this.task.date_due.date);
        }
        form.append("estimated_hours", this.task.estimated_hours);
        form.append("task_type", this.task.task_type);

        form.append(
          "brief",
          this.newBrief ? this.newBrief : this.task.brief ? this.task.brief : ""
        );
        if (this.task.reviewed_by) {
          form.append("reviewed_by", this.task.reviewed_by);
        }
        form.append("task_files", JSON.stringify(this.task_files));

        await this.$axios.post(
          process.env.VUE_APP_ROOT_API + "/v1/tasks/" + this.task.uid,
          form,
          {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          }
        );
        await fetchTask(this.task.uid);
        this.task = state.task;
        this.isLoading = false;
        this.$store.dispatch("setSliderLoader", false);
        this.editBrief = false;
        this.hasBrief = this.task.brief ? true : false;
        this.showDrop = false;
        this.$emit("cancel");
        this.$toast.add({
          severity: "success",
          summary: "Task Brief Saved",
          life: 3000,
        });
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("setSliderLoader", false);
        if (error.response && error.response.status === 401) {
          console.error("NOOOOOOOOO!");
        } else {
          // Handle error however you want
        }
        console.error(error);
      }
    },
    handleProcessFile: function (error, file) {
      const response = JSON.parse(file.serverId);
      this.task_files.push(response.data);
    },
    handleFilePondInit: function () {
      this.$refs.pond.getFiles();
    },
  },
};
</script>

<style>
</style>